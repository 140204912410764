import React, { useEffect, useState } from "react";
import * as styles from "./footer.module.css";
import zector from "../../images/zector.png";
import facebook from "../../images/social_icons/facebook.png";
import instagram from "../../images/social_icons/instagram.png";
import twitter from "../../images/social_icons/twitter.png";
//import msg from "../../images/social_icons/msg.png";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useMatch } from "@reach/router";
import { Link } from "gatsby";
export default function Footer() {
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 300);
    });
  }, []);

  const { register, handleSubmit } = useForm();
  const match = useMatch("/contact");

  const url = "https://api.convertkit.com/v3/forms/1517083/subscribe";
  const [email, setEmail] = useState("");
  const [confirm, setConfirm] = useState(false);

  const [stateModal, setModalState] = React.useState("");
  React.useEffect(() => {
    // Run! Like go get some data from an API.
    setModalState("is-active");
  }, []);

  const hideModal = () => {
    setModalState("");
    setConfirm(false);
  };

  const handleInputChange = (event) => {
    setEmail(event.target.value);
  };

  const _0x1a71 = [
    "stringify",
    "POST",
    "application/json",
    "0zpX0t4cAOUkcHE1mMjt3w",
  ];
  (function (_0x44b6ec, _0x1a71f3) {
    const _0x513184 = function (_0x590d75) {
      while (--_0x590d75) {
        _0x44b6ec["push"](_0x44b6ec["shift"]());
      }
    };
    _0x513184(++_0x1a71f3);
  })(_0x1a71, 0x184);
  const _0x5131 = function (_0x44b6ec, _0x1a71f3) {
    _0x44b6ec = _0x44b6ec - 0x0;
    let _0x513184 = _0x1a71[_0x44b6ec];
    return _0x513184;
  };
  const config = {
    method: _0x5131("0x1"),
    headers: { Accept: _0x5131("0x2"), "Content-Type": _0x5131("0x2") },
    crossDomain: !![],
    url: url,
    data: JSON[_0x5131("0x0")]({ api_key: _0x5131("0x3"), email: email }),
  };

  const submitEmail = (event) => {
    //event.preventDefault();
    axios(config)
      .then((res) => {
        if (res.status === 200) {
          //alert("Thank you for subscribing!");
          setModalState("is-active");
          setConfirm(true);
        }
      })
      .catch((err) => console.log(err));
    setEmail("");
  };

  return (
    <div className={`${styles.footer_main} bs-bb`}>
      <div className="main-container container-lg d-flex flex-column">
        <div className={`${styles.footer_content} d-flex flex-wrap`}>
          <div className="d-flex-sm justify-center-sm flex-16 flex-100-lg bs-bb pr-30 pl-30 pl-15-lg pr-15-lg mb-60">
            <Link to="/" className="nav_logo no-underline d-flex mb-30">
              <img className=" w-100" src={zector} alt="logo" />
            </Link>
          </div>
          <div
            className={`${styles.social_content} text-white d-flex justify-between item-center-sm flex-100-sm or-3-es mb-60 flex-100-es flex-column item-center justify-center flex-33 flex-1-md bs-bb pl-15-lg pr-15-lg pr-30 pl-30`}
          >
            <div
              className={styles.footer_text}
              style={{ fontFamily: `Montserrat, sans-serif` }}
            >
              <p className="">9466 Georgia Ave #1147</p>
              <p>Silver Spring MD 20910</p>
            </div>
            <div className={`${styles.social_icons} d-flex`}>
              <a
                className="no-underline "
                href="https://www.facebook.com/Zector-103556434668793/?view_public_for=103556434668793"
              >
                {" "}
                <img className="w-80" src={facebook} alt="fb" />
              </a>
              <a
                className="no-underline  "
                href="https://twitter.com/zector_io"
              >
                {" "}
                <img className="w-80" src={twitter} alt="fb" />
              </a>
              <a
                className="no-underline "
                href="https://www.instagram.com/zector.io/"
              >
                {" "}
                <img className="w-80" src={instagram} alt="fb" />
              </a>
            </div>
          </div>
          <div
            className={`${styles.subscribe} text-white justify-between item-center-sm flex-100-sm or-1-es mb-60 d-flex flex-column flex-100-es item-center justify-center flex-33 flex-1-md bs-bb pl-15-lg pr-15-lg pr-30 pl-30`}
          >
            <div
              className={styles.footer_text}
              style={{ fontFamily: `Montserrat, sans-serif` }}
            >
              <p>Ready to build an app?</p>
              <p>Subscribe for a free guide</p>
            </div>
            {match ? (
              ""
            ) : (
              <form
                className={`${styles.input_box} d-flex flex-wrap flex-column-sm item-center justify-center `}
                onSubmit={handleSubmit(submitEmail)}
                style={{ fontFamily: `Montserrat, sans-serif` }}
              >
                <input
                  type="text"
                  placeholder="E-mail"
                  className="mr-10"
                  id={styles.email_input}
                  style={{
                    fontSize: "20px",
                    fontFamily: `Montserrat, sans-serif`,
                  }}
                  name="email"
                  value={email}
                  onChange={handleInputChange}
                  ref={register({
                    required: true,
                    pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  })}
                ></input>
                <button
                  className={`${styles.subs_btn} d-flex item-center justify-center mt-20`}
                  style={{ fontFamily: `Montserrat, sans-serif` }}
                >
                  Subscribe
                </button>
              </form>
            )}
          </div>
          <div
            className={`${styles.footer_text} text-center-lg text-white item-center-sm flex-100-sm or-2-es mb-30 flex-1-md flex-16 flex-100-sm bs-bb pr-30 pl-30 pl-15-lg pr-15-lg text-right`}
            style={{ fontFamily: `Montserrat, sans-serif` }}
          >
            <p>info@zector.io</p>
            <p>+1 (276) 218-2213</p>
          </div>
        </div>
        <div
          className={`${styles.copy_right} align-self-center text-white d-flex ps-relative`}
          style={{ fontFamily: `Montserrat, sans-serif` }}
        >
          <p>© Zector, LLC 2020</p>
        </div>
      </div>
    </div>
  );
}
