// extracted by mini-css-extract-plugin
export const footer_main = "footer-module--footer_main--4cq1V";
export const footer_text = "footer-module--footer_text--bwadl";
export const social_content = "footer-module--social_content--2DBUR";
export const input_box = "footer-module--input_box--1VYdf";
export const email_input = "footer-module--email_input--2_rcn";
export const subscribe = "footer-module--subscribe--2X2rR";
export const social_icons = "footer-module--social_icons--3Nuwe";
export const subs_btn = "footer-module--subs_btn--1UejD";
export const copy_right = "footer-module--copy_right--yfDOy";
export const icon_scroll = "footer-module--icon_scroll--15YK-";