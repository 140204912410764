import React, { useState } from "react";
import * as  styles from "./navbar.module.css";
import zector from "../../images/zector.png";
import { AiOutlineMenu } from "@react-icons/all-files/ai/AiOutlineMenu";
import { Link } from "gatsby";

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  return (
    <nav className={styles.navbar} style={{fontFamily: `Montserrat, sans-serif`}}>
      <div className=" main-container container-lg ps-relative d-flex item-center justify-between pl-15 pr-15 bs-bb h-100">
        <Link to="/">
          <img className={styles.zector_img} src={zector} alt="" />
        </Link>
        <ul className=" li-style-none d-flex d-none-md h-100">
          <li className="mr-30">
            <Link
              className="no-underline pr-15 text-white h-100 d-flex item-center"
              to="/about"
            >
              About
            </Link>
          </li>
          <li className="mr-30">
            <Link
              className="no-underline pr-15 text-white h-100 d-flex item-center"
              to="/process"
            >
              Process
            </Link>
          </li>
          <li className="mr-30">
            <Link
              className="no-underline pr-15 text-white h-100 d-flex item-center"
              to="/services"
            >
              Services
            </Link>
          </li>
          <li className="mr-30">
            <Link
              className="no-underline pr-15 text-white h-100 d-flex item-center"
              to="/blog"
            >
              Blog
            </Link>
          </li>
          <li className="mr-30">
            <Link
              className="no-underline text-white h-100 d-flex item-center"
              to="/case-studies"
            >
              Case Studies
            </Link>
          </li>
          <li className="mr-30">
            <Link
              className="no-underline text-white h-100 d-flex item-center"
              to="/contact"
            >
              Contact
            </Link>
          </li>
        </ul>
        <a
          onClick={toggle}
          className={`${styles.nav_icon} text-white  d-none-lg d-block-lg d-block-md`}
        >
          <AiOutlineMenu />
        </a>
        <div
          className={`${styles.mobile_nave} ${
            isOpen ? "d-block" : " d-none"
          } ps-absolute w-100 m-auto bg-black`}
        >
          <ul className=" li-style-none d-flex flex-column item-center w-100 h-100">
            <li className="mb-10">
              <Link
                className="no-underline  text-white w-100 d-flex justify-center"
                to="/about"
              >
                About
              </Link>
            </li>
            <li className="mb-10">
              <Link
                className="no-underline  text-white w-100 d-flex justify-center"
                to="/process"
              >
                Process
              </Link>
            </li>
            <li className="mb-10">
              <Link
                className="no-underline text-white w-100 d-flex justify-center"
                to="/services"
              >
                Services
              </Link>
            </li>
            <li className="mb-10">
              <Link
                className="no-underline text-white w-100 d-flex justify-center"
                to="/blog"
              >
                Blog
              </Link>
            </li>
            <li className="mb-10">
              <Link
                className="no-underline  mr-10 text-white w-100 d-flex justify-center"
                to="/case-studies"
              >
                 Case Studies
              </Link>
            </li>
            <li className="mb-10">
              <Link
                className="no-underline text-white w-100 d-flex justify-center"
                to="/contact"
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
