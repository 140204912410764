import React from "react";
import Navbar from "./menu/Navbar";
import Footer from "./footer/footer";

export const Layout = ({ children }) => {
  return (
    <div style={{fontFamily: `Montserrat, sans-serif`}}>
      <Navbar />
      {children}
      <Footer />
    </div>
  );
};
